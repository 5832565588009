<template>
  <div class="home">
<!--    <div class="top_style">-->
<!--      <img alt="Vue logo" src="../../public/logo.png" style="width: 42px; height: 42px;margin-left: 15px">-->
<!--      <span class="span_center">Lucky Maker</span>-->

<!--      <div style="width: 100%;">-->
<!--        <div style="float: right;margin-right: 15px;">-->
<!--          <img alt="Vue logo" src="../assets/img/instagram.png" style="margin-left: 15px">-->
<!--          <img alt="Vue logo" src="../assets/img/in.png" style="margin-left: 15px">-->
<!--          <img alt="Vue logo" src="../assets/img/facebook.png" style="margin-left: 15px">-->
<!--          <img alt="Vue logo" src="../assets/img/bird.png" style="margin-left: 15px">-->
<!--          <img alt="Vue logo" src="../assets/img/youtobe.png" style="margin-left: 15px">-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

    <router-view style="height: 100%"></router-view>

<!--    <div style="padding-bottom: 30px;padding-top:30px;background: #2F1A5E;text-align: center">-->
<!--      <span class="sp_type" @click="jumpPrivacyPolicy">privacy policy</span>-->
<!--      <span class="sp_type" @click="jumpUsePolicy" style="margin-left: 60px">Terms of use</span>-->

<!--      <div class="flex_center" style="margin-top: 30px">-->
<!--        <img alt="Vue logo" src="../assets/img/instagram.png">-->
<!--        <img alt="Vue logo" src="../assets/img/in.png" style="margin-left: 40px">-->
<!--        <img alt="Vue logo" src="../assets/img/facebook.png" style="margin-left: 40px">-->
<!--        <img alt="Vue logo" src="../assets/img/bird.png" style="margin-left: 40px">-->
<!--        <img alt="Vue logo" src="../assets/img/youtobe.png" style="margin-left: 40px">-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script>
export default {
  name: "home",

  methods: {
    calculateSize() {

    },

  },
}
</script>
<style>
@import "../assets/css/style2016.css";
@import "../assets/css/transition.css";
@import "../assets/css/main.css";

home {
}



</style>