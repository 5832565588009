import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '../components/home';

Vue.use(VueRouter)


const routes = [{
    path: '/',
    component: home,
    children: [
        {path: '/', component: resolve => require(['../views/main/index'], resolve)},
        {path: 'detail', component: resolve => require(['../views/main/detail'], resolve)},
        {path: 'UserAgreement', component: resolve => require(['../views/main/UserAgreement'], resolve)},
        {path: 'PrivacyAgreement', component: resolve => require(['../views/main/PrivacyAgreement'], resolve)},
        {path: 'aboutUs', component: resolve => require(['../views/main/AboutUs'], resolve)},
        {path: 'contactUs', component: resolve => require(['../views/main/ContactUs'], resolve)},
    ]
},
]

const router = new VueRouter({
    routes
})

export default router